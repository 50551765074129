import * as React from 'react';
import styled from 'styled-components';
import { D } from '@dayetopia/library';

const NotFoundPage = () => (
  <Container>
    <D.H1>Sorry, that page doesn't seem to exist : (</D.H1>
  </Container>
);

const Container = styled.div`
  padding: 100px;
`;

export default NotFoundPage;
